<template>
  <p class="mapper-container">
    <img
      class="map"
      :width="mapper.width"
      :height="mapper.height"
      :src="mapper.image"
      :usemap="`#${mapContent.name}`"
    />

    <map :name="mapContent.name">
      <area
        v-for="(x, i) in mapContent.areas"
        :key="i"
        :index="x"
        @click="alertMap((mapContent.areas[i].status == 1)?mapContent.areas[i].folio:'Sin Asignar')"
        :alt="`Unidad: ${mapContent.areas[i].folio}`"
        shape="poly"
        :data-maphilight="mapContent.areas[i].colorCode"
        :coords="mapContent.areas[i].coords"
        :title="mapContent.areas[i].statusName"
        class="cc_pointer"
      />
    </map>
    <!-- Alert section -->
    <main id="alertContent" style="display:none;">
      <div v-if="infoUnit"  class="property-topinfo cc_cursor" style="padding: 20px;">
        <div class="row" style="padding: 5px;font-size:16px">  
          <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
              <b > Folio: </b>
              <br>
              <span>{{infoUnit.folio}}</span>
          </div>
          <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
              <b > Área: </b>
              <br>
              <span>{{infoUnit.m2c}} m<sup>2</sup></span>
          </div>
          <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
              <b > Costo: </b>
              <br>
              <span>{{infoUnit.priceFinance}} </span>
          </div>
          <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
              <b > Disponibilidad: </b>
              <br>
              <span>{{infoUnit.status}} </span>
          </div>                   
          <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
              <b > Plazo: </b>
              <br>
              <select id="term" name="term" data-placeholder="Plazo" v-model="termSelected">
                  <option v-for="(term,i) in infoUnit.term" :key="term" :index="i" :value="i">{{infoUnit.term[i]}}</option>
              </select>
          </div>
          <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
              <b > Enganche: </b>
              <br>
              <span>{{infoUnit.inicial}} </span>
          </div>
          
          <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
              <b > Mensualidad: </b>
              <br>
              <span>{{infoUnit.termPrice[`${termSelected}`]}}</span>
          </div>
        </div>
      </div>
    </main>
  </p>
</template>
<script>
export default {
  props: {
    mapper: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      infoUnit: 0,
      termSelected:"1",
      map:{},
      mapContent:{
        name:'',
        areas:[]
      }
    }
  },
  watch:{
    infoUnit: function () {
      var self = this;
      setTimeout(function(){
        self.createAlert();  
      },300)
    }
  },
  created(){
    this.map=this.mapper.poly;
    this.configMap();
  },
  mounted() {
    this.testMaphilight();
  },
  methods: {
    testMaphilight() {
      let mapper = $(".map");
      mapper.maphilight();
    },
    createAlert(){
      var self=this;
      let template = document.getElementById('alertContent').innerHTML//self.$refs.alertContent;
      self.$swal({
        title: `<b>${self.infoUnit.name}</b>`,
        icon: "info",
        html: `<div><alert-comp>${template}</alert-comp></div>`,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Cerrar",
        confirmButtonAriaLabel: "Thumbs up, great!",
        width: "60em",
        font: "1rem",
      });
    },
    alertMap(i) {
      let self = this;
      console.log(i);
      if (i != "Sin Asignar") {
        $.ajax({
          url: "https://api.immocrm360.com.mx",
          type: "post",
          data: {
            m: "infoUnit",
            folio: i,
          },
          success: function(response) {
            if (response.status == 200) {
              self.infoUnit=response.data
            }
          },
          error: function() {
            console.log("falló el envio de datos");
          },
        });
      }else {
        console.log('no funca')
        self.$swal({
          title: `<b>UNIDAD NO DISPONIBLE</b>`,
          icon: "error",
          //text:'A esta secci[on] no se le a asignado unidad',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Cerrar",
          confirmButtonAriaLabel: "Thumbs up, great!",
          //width: '80em',
          font: "3rem",
        });
      }
    },
    getInfoMap(map, att) {
      if (map.search(att) != "-1") {
        var newMap = map.split(att + '="');
        var filtMap = newMap[1].split('"');
        return filtMap[0];
      } else {
        filtMap = "Sin Asignar";
        return filtMap;
      }
    },
    configMap(){
      this.mapContent.name=this.getInfoMap(this.map,'name');
      //get all areas of map
      let areas=this.map.split('area');
      //Delete firs element (junk content)
      areas.shift();

      for(let x in areas){
        let contentArea={};
        contentArea.coords=this.getInfoMap(areas[x], 'coords');
        contentArea.folio=this.getInfoMap(areas[x], 'data-id');
        contentArea.status=this.mapper.units.find( item => item.propertyid == contentArea.folio ).status;
        switch(contentArea.status){
          case '1':
            contentArea.colorCode = '{"fillColor":"93E945", "strokeColor":"93E945"}';
            contentArea.statusName='Disponible';
            break;
          case '2':
            contentArea.colorCode = '{"fillColor":"FFC848", "strokeColor":"FFC848"}';
            contentArea.statusName='Reservado';
            break;
          default:
            contentArea.colorCode = '{"fillColor":"E94545", "strokeColor":"E94545"}';
                        contentArea.statusName='No Disponible';
            break;
        }
        this.mapContent.areas.push(contentArea);
      }
    }
  },
};
</script>
<style scoped>
area {
  cursor: pointer !important;
}
.mapper-container {
  max-height: 700px;
  overflow: auto;
}
</style>
